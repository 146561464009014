<template>
  <div class="vuse-content-wrapper">
    <v-container fluid class="fill-height">
      <v-row justify="start" align="start">
        <TrainerByMe/>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import TrainerByMe from '../Pages/TrainerListingPage/TrainersByMe.vue'
export default {
  computed: {
    ...mapState('project', {
      user: 'user',
      s3Directory: 's3Directory',
      access: 'access',
      mode: 'mode',
      snackbar: 'snackbar',
      notice: 'notice',
      alertBoxText: 'alertBoxText',
      alertBoxStatus: 'alertBoxStatus',
      afterOk: 'afterOk'
    }),
  },
  data() {
    return {
      page: "Home",
      breadcrumbs: [
        {
          text: "Home",
          disabled: true,
        },
      ],
    };
  },
  components:{
    TrainerByMe
  }
};
</script>
